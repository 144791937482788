import { createReducer, createAction } from "redux-act";
import { takeLatest, put } from "redux-saga/effects";
import { createSagaActions } from "./utils";
import {
  getUsers as getUsersFromAPI,
  getArchivedUsers as getArchivedUsersFromAPI,
  getBudgetRoles as getBudgetRolesFromAPI,
  getUserRoles as getUserRolesFromAPI,
  getMe as getMefromAPI,
  getRateCards as getRateCardsFromAPI,
} from "../../crud/app.crud";
import {
  createUser as createUserFromAPI,
  blockUser as blockUserAPI,
} from "../../crud/users.crud";
import { setHolidayAllowance as setHolidayAllowanceAPI } from "../../crud/users.crud";
import { GetAccessToken } from "./accessToken";
import orderBy from "lodash/orderBy";

export const createUser = createSagaActions("app/CREATE_USER");
export const blockUser = createSagaActions("app/BLOCK_USER");
export const getUsers = createSagaActions("app/GET_USERS");
export const getUsersAll = createSagaActions("app/GET_USERS_ALL");
export const getBudgetRoles = createSagaActions("app/GET_ROLES");
export const getUserRoles = createSagaActions("app/GET_ROLES");

export const getMe = createSagaActions("app/GET_ME");
export const getRateCards = createSagaActions("app/GET_RATECARDS");
export const setHolidayAllowance = createSagaActions(
  "app/SET_HOLIDAY_ALLOWANCE"
);

export const sortUsers = createAction("app/SORT_USERS");

const initialState = {
  me: null,
  users: [],
  allUsers: [], //includes all archived users
  loading: false,
  usersLoading: false,
  usersSortKey: "name",
  usersSortOrder: "ASC",
  budgetRoles: [],
  userRoles: [],
  rateCards: null,
  holidayLoading: false,
  userCreationFailed: false,
};

export const reducer = createReducer(
  {
    [createUser]: (state) => ({
      ...state,
      userCreationFailed: false,
      loading: true,
    }),
    [createUser.done]: (state) => ({ ...state, loading: false }),
    [createUser.fail]: (state) => ({
      ...state,
      userCreationFailed: true,
      loading: false,
    }),
    [blockUser]: (state) => {
      return { ...state, loading: true };
    },
    [blockUser.done]: (state) => {
      return { ...state, loading: false };
    },
    [blockUser.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getUsers]: (state) => {
      return { ...state, loading: true };
    },
    [getUsers.done]: (state, data) => {
      return { ...state, loading: false, users: data };
    },
    [getUsers.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getUsersAll]: (state) => {
      return { ...state, loading: true };
    },
    [getUsersAll.done]: (state, data) => {
      return { ...state, loading: false, allUsers: data };
    },
    [getUsersAll.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getBudgetRoles]: (state) => {
      return { ...state, loading: true };
    },
    [getBudgetRoles.done]: (state, data) => {
      return { ...state, loading: false, budgetRoles: data };
    },
    [getBudgetRoles.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getUserRoles]: (state) => {
      return { ...state, loading: true };
    },
    [getUserRoles.done]: (state, data) => {
      return { ...state, loading: false, userRoles: data };
    },
    [getUserRoles.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getMe]: (state) => {
      return { ...state, loading: true };
    },
    [getMe.done]: (state, data) => {
      return { ...state, loading: false, me: data };
    },
    [getMe.fail]: (state) => {
      return { ...state, loading: false };
    },
    [getRateCards]: (state) => {
      return { ...state, loading: true };
    },
    [getRateCards.done]: (state, data) => {
      return { ...state, loading: false, rateCards: data };
    },
    [getRateCards.fail]: (state) => {
      return { ...state, loading: false };
    },
    [setHolidayAllowance]: (state) => ({
      ...state,
      holidayLoading: true,
    }),
    [setHolidayAllowance.done]: (state) => ({
      ...state,
      holidayLoading: false,
    }),
    [setHolidayAllowance.fail]: (state) => ({
      ...state,
      holidayLoading: false,
    }),
    [sortUsers]: (state, { key, sortOrder }) => {
      const items = state.users;
      const by = (key === "name" && ["lastName", "firstName"]) ||
        (key === "role" && ["budgetRoleName"]) || [key];
      const direction = by.map((_) => sortOrder.toLowerCase());
      return {
        ...state,
        users: orderBy(items, by, direction),
        usersSortKey: key,
        usersSortOrder: sortOrder,
      };
    },
  },
  initialState
);

export function* saga() {
  yield takeLatest(getUsers, function* getUsersSaga(...args) {
    const token = yield* GetAccessToken();
    const result = yield getUsersFromAPI(token);
    yield put(getUsers.done(result.data));
  });

  yield takeLatest(getUsersAll, function* getUsersAllSaga(...args) {
    const token = yield* GetAccessToken();
    const result = yield getArchivedUsersFromAPI(token);
    yield put(getUsersAll.done(result.data));
  });

  yield takeLatest(getBudgetRoles, function* getBudgetRolesSaga(...args) {
    try {
      const token = yield* GetAccessToken();
      const result = yield getBudgetRolesFromAPI(token);
      yield put(getBudgetRoles.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getBudgetRoles.fail(error));
    }
  });

  yield takeLatest(getUserRoles, function* getUserRolesSaga(...args) {
    try {
      const token = yield* GetAccessToken();
      const result = yield getUserRolesFromAPI(token);
      yield put(getUserRoles.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getUserRoles.fail(error));
    }
  });

  yield takeLatest(getMe, function* getMeSaga(...args) {
    try {
      const token = yield* GetAccessToken();
      const result = yield getMefromAPI(token);
      yield put(getMe.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getMe.fail(error));
    }
  });
  yield takeLatest(getRateCards, function* getRateCardsSaga() {
    try {
      const token = yield* GetAccessToken();
      const result = yield getRateCardsFromAPI(token);
      yield put(getRateCards.done(result.data));
    } catch (error) {
      console.log(error);
      yield put(getRateCards.fail(error));
    }
  });
  yield takeLatest(setHolidayAllowance, function* setHolidayAllowanceSaga({
    payload: data,
  }) {
    try {
      const token = yield* GetAccessToken();
      const result = yield setHolidayAllowanceAPI(token, data);
      yield put(setHolidayAllowance.done(result.data));
      yield put(getUsers());
    } catch (error) {
      console.log(error);
      yield put(setHolidayAllowance.fail(error));
    }
  });

  yield takeLatest(createUser, function* createUserSaga({ payload: data }) {
    try {
      const token = yield* GetAccessToken();
      const result = yield createUserFromAPI(token, data);
      console.log(result);
      yield put(createUser.done());
      yield put(getUsers());
    } catch (error) {
      console.log(error);
      yield put(createUser.fail(error));
    }
  });

  yield takeLatest(blockUser, function* blockUserSaga({ payload: { userId } }) {
    try {
      const token = yield* GetAccessToken();
      yield blockUserAPI(token, userId);
      yield put(blockUser.done());
      yield put(getUsers());
    } catch (error) {
      console.log(error);
      yield put(blockUser.fail(error));
    }
  });
}
