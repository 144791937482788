import { baseURL } from "./helper";
import axios from "axios";

const config = (token) => ({
  baseURL,
  headers: { Authorization: `Bearer ${token}` },
});
export function createUser(token, body) {
  return axios.post(`/users`, body, config(token));
}

export function blockUser(token, userId) {
  return axios.put(`/users/${userId}/block`, {}, config(token));
}

export function setHolidayAllowance(token, body) {
  return axios.post(`/users/holiday/allowance`, body, config(token));
}

export function getHolidayBookingsAPI(token) {
  return axios.get("/users/holiday/booking", config(token));
}

export function deleteHolidayAPI(token, bookingId) {
  return axios.delete(`users/holiday/booking/${bookingId}`, config(token));
}
export function getHolidayApprovalsAPI(token) {
  return axios.get("/users/holiday/booking/approvals", config(token));
}
export function getHolidayAdminListAPI(token) {
  return axios.get("/users/holiday/booking/admin", config(token));
}

export function getHolidayCalendarAPI(token) {
  return axios.get("/users/holiday/calendar", config(token));
}

export function bookHolidayAPI(token, body) {
  if (body.user) {
    return axios.post(
      `/users/holiday/booking/${body.user}`,
      body,
      config(token)
    );
  }
  return axios.post("/users/holiday/booking", body, config(token));
}

export function checkDaysHolidayAPI(body) {
  return axios.post("/users/holidaydays", body, { baseURL });
}

export function cancelHolidayAPI(token, bookingId) {
  return axios.put(
    `/users/holiday/booking/${bookingId}/cancel`,
    null,
    config(token)
  );
}

export function approveHolidayAPI(token, bookingId) {
  return axios.put(
    `/users/holiday/booking/${bookingId}/approve`,
    null,
    config(token)
  );
}

export function declineHolidayAPI(token, bookingId, note) {
  return axios.put(
    `/users/holiday/booking/${bookingId}/decline`,
    { note },
    config(token)
  );
}
